
.details-label {
  font-weight: bold;
  width: 120px;
}

.details-first-column {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 600px;
}

.details-second-column {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.active-class-name {
  background-color:#DCDCDC !important;
}